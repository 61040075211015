import { Link } from "react-router-dom";
import AppActions from "./AppActions";
import BaseCom from "./common/BaseCom";
import DataTable from "./common/DataTable";
import { withParamsAndNavigate } from "./common/WithParamsAndNavigate";
import { NavMenuGlobal } from "./NavMenu";
import Icon from "./common/Icon";
import { qreq } from "../shared/qrequest";

class UserList extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
    }

    componentDidMount() {
        NavMenuGlobal.setPath('Users');

        qreq.get('/api/user/list', j => {
            if (j.list)
                this.setState({ list: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);

    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }


    render() {
        
        return <div className="container-fluid">
            <AppActions>
                <Link to="/console/user/new" type="button" className="btn btn-primary btn-lg"><Icon icon="faPlus" /></Link>
            </AppActions>
            <DataTable columns={[
                { name: 'id', title: 'ID', width: '100px' },
                { name: 'instanceName', title: 'Instance' },
                { name: 'email', title: 'Email' },
                { name: 'name', title: 'Name', valueFunc: row => row.user_name },
                { name: 'actions', title: 'Actions', width: '100px', 
                    valueFunc: row => <div className="d-flex gap-1">
                        <Link to={`/console/user/edit/${row.id}`} type="button" className="btn btn-primary btn-sm"><Icon icon="faPencil" /></Link>
                        <Link to={`/console/user/delete/${row.id}`} type="button" className="btn btn-danger btn-sm"><Icon icon="faTrash" /></Link>
                    </div>
                }
            ]} data={this.state.list} />
        </div>;
    }
}

export default withParamsAndNavigate(UserList);